<template>
    <div class="flex_layout inner">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-button class="primary btn" @click="update('add')">Add</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
               <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                   <template #action="{ record }">
                       <a-space>
                           <a-button class="primary btn" @click="update('edit', record)" size="small" type="primary">Edit</a-button>
                           <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.dep_id)"
                            >
                               <a-button danger size="small">Delete</a-button>
                           </a-popconfirm>
                       </a-space>
                   </template>
               </a-table>
            </a-spin>
        </div>
        <a-drawer
            title="Department"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <a-spin :spinning="spinning">
                    <div class="form">
                        <div class="form_title">Department</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Department name">
                                <a-input v-model:value="formData.department_name" placeholder="Department name" />
                            </a-form-item>
                            <a-form-item label="Department class">
                                <a-select
                                    ref="select"
                                    v-model:value="formData.department_class"
                                    :options="departmentData"
                                    placeholder="Select department class"
                                >
                                </a-select>
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" @click="updateDepartmentHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                   </a-spin>
               </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'ID',
        dataIndex: 'dep_id',
        key: 'dep_id',
    },
    {
        title: 'Department name',
        dataIndex: 'department_name',
        key: 'department_name',
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { message } from 'ant-design-vue'
import { getDepartmentList, updateDepartment, removeDepartment } from '@/apis/department'
import departmentData from '@/config/department.data'
export default {
    name: 'Department',
    data(){
        return {
            dataSource: [],
            columns,
            visible: false,
            formData: {},
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false,
            departmentData 
        }
    },
    methods: {
        update(actionType, depInfo){
            if(actionType == 'add'){
                this.initParams()
            }
            if(actionType == 'edit'){
                this.formData = depInfo
            }
            this.formData.action_type = actionType
            this.visible = true
        },
        async updateDepartmentHandler(){
            if(this.formData.department_name == '') return message.error('Please input class name')
            this.spinning = true
            const res = await updateDepartment(this.formData)
            this.spinning = false
            if(res){
                this.visible = false
                this.getDepartmentListHandler()
            } 
        },
        async remove(dep_id){
            this.spinning = true
            const res = await removeDepartment({dep_id})
            this.spinning = false
            if(res){
                this.getDepartmentListHandler()
            } 
        },
        onClose(){
            this.visible = false
        },
        async getDepartmentListHandler(){
            this.spinning = true
            const res = await getDepartmentList()
            this.spinning = false
            if(res){
                this.dataSource = res.list  
            }
        },
        initParams(){
            this.formData = {
                department_name: '',
                dep_id: 0,
                action_type: 'add',
                department_class: 1,
            }
        }
    },
    created(){
        this.getDepartmentListHandler()
    }
}
</script>
<style lang="stylus" scoped>
</style>