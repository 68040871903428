import request from '@/request'

export const getDepartmentList = (data = {}) => {
  return request({
    url: '/admin/department/get_department_list',
    method: 'get',
    params: data
  })
}
export const updateDepartment = (data = {}) => {
return request({
  url: '/admin/department/update_department',
  method: 'post',
  data
})
}
export const removeDepartment = (data = {}) => {
return request({
  url: '/admin/department/remove_department',
  method: 'post',
  data
})
}